<template>
  <div class="product-detail">
    <s-header :name="$t('t.productDetails')" :back="'/home'" :goodsId="detail.goodsId" ></s-header>
    <div class="detail-content">
      <div class="detail-swipe-wrap">
        <van-swipe class="my-swipe" indicator-color="#e92d74" v-if="detail.goodsCoverVideo">
          <van-swipe-item v-for="(item, index) in JSON.parse(detail.goodsCoverVideo)" :key="'goodsCoverVideo'+index">
            <!--poster="../assets/banner1.jpg"-->
            <video :src="item"
                  :poster="JSON.parse(detail.goodsCoverImg)[0]"
              class="borderF2 marBott10" style="opacity: 0.99"
              controls="controls"
              width="100%" :height="boxHeight" preload="metadata">
              <source :src="item" type="video/ogg">
              <source :src="item" type="video/webm">
              <source :src="item" type="video/mp4">
            </video>
          </van-swipe-item>
          <van-swipe-item v-for="(item, index) in JSON.parse(detail.goodsCoverImg)" :key="'goodsCoverImg'+index">
            <img :src="prefix(item)" alt="">
          </van-swipe-item>
        </van-swipe>
        <!--<img style="width: 100%" :src="prefix(detail.goodsCoverImg)" alt="">-->
      </div>
      <div class="product-info">
        <div class="product-title">
          {{ detail.goodsName }}
        </div>
        <van-row type="flex" align="center" justify="space-between">
          <div class="product-desc">{{$t('t.exemptFromPostage')}}</div>
          <div class="product-desc">{{detail.tag}}</div>
        </van-row>
        <div class="product-price">
          <span>{{ detail.sellingPrice | wq_mFormat}}</span>
          <span v-if="detail.salesVolume" style="font-size: 16px">
            <span style="color: green;font-size: 18px">{{detail.salesVolume}}</span>{{$t('t.salesVolume')}}</span>
        </div>
      </div>
      <van-tabs v-model="tabActive" style="border-top: 1px solid #f2f2f2;margin-top: 15px;background: #f5f5f5;flex: 1;padding-bottom: 50px">
        <van-tab :title="$t('t.overviewParameter')" name="1">
          <div class="product-content" v-html="detail.goodsDetailContent"></div>
        </van-tab>
        <van-tab :title="$t('t.evaluate')+'('+total+')'" name="2">
          <van-row  v-for="(item,i) in evaluateList" :key="i" class="padding15 box">
            <van-row type="flex" align="center">
              <img class="headSculptureImage" :src="item.headSculptureImage" alt="">
              <van-row class="nameBox">{{item.name}}</van-row>
            </van-row>

            <van-row class="contBox">
              <van-row type="flex" align="center" justify="space-between" class="color99">
                <van-row>{{$t('t.purchased')}}：{{item.goodsDescription}}</van-row>
                <van-row>{{filterDate(item.createTime)}}</van-row>
              </van-row>
              <van-row class="level">
                {{item.level==1?$t('t.negativeComment'):item.level==2 || item.level==3?$t('t.mediumReview'):$t('t.goodReputation')}}：
                <van-rate
                  readonly
                  v-model="item.level"
                  :size="15"
                  color="#ffd21e"
                  void-icon="star"
                  void-color="#eee"
                />
              </van-row>
              <van-row class="content">{{item.content}}</van-row>
              <van-row type="flex" align="center">
                <van-row  v-for="(img,i) in JSON.parse(item.images)" :key="i" class="imgBox">
                  <van-image
                    width="100"
                    height="100"
                    fit="cover"
                    v-if="isImg(img)" :src="img" @click="imgBigUrl= JSON.parse(item.images),startIndex = i,imgBool = true"
                  />
                  <!--<img v-if="isImg(img)" :src="img" @click="imgBigUrl= img,imgBool = true" class="avatar" style="width:100%;height: 100%;"/>-->
                  <!--:src="img" @loadeddata="setVideoPoster($event)"-->
                    <video controls="controls" autoplay="autoplay" muted="muted"
                           style="width:100%;height: 100%;"
                           width="100%" height="100%"
                           preload="auto">
                      <source :src="img" type="video/ogg">
                      <source :src="img" type="video/webm">
                      <source :src="img" type="video/mp4">
                    </video>

                </van-row>
              </van-row>
            </van-row>
          </van-row>
        </van-tab>
      </van-tabs>
      <div class="product-intro" v-if="false">
        <ul>
          <li>概述</li>
          <li>参数</li>
          <!--<li>安装服务</li>-->
          <!--<li>常见问题</li>-->
        </ul>
        <div class="product-content" v-html="detail.goodsDetailContent"></div>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-icon icon="chat-o" @click="customerServe()" :text="$t('t.customerService')"/>
      <van-goods-action-icon icon="cart-o" :info="!count ? '' : count" @click="goTo()" :text="$t('t.shoppingCart')"/>
      <van-goods-action-button type="warning" @click="addCart" :text="$t('t.addCart')" />
      <van-goods-action-button type="danger" @click="goToCart" :text="$t('t.buyNow')" />
    </van-goods-action>
    <van-image-preview getContainer="body" showIndex :startPosition="startIndex" v-model="imgBool" :images="imgBigUrl"></van-image-preview>
    <van-popup v-model="weChatBool">
      <van-row class="width250 flexDirection padding20" type="flex" align="center" justify="center">
        <van-row class="marBott10 size16 fontB">请使用手机QQ扫描二维码</van-row>
        <img class="app" src="../assets/image/qqService.jpg" alt="">
        <van-row class="marTop10 size14">QQ：512612269</van-row>
        <van-row class="marTop10 size14">昵称：深圳暴富先生科技有限公司</van-row>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
import { getDetail,getEvaluateList } from '../service/good'
import { addCart } from '../service/cart'
import sHeader from '@/components/SimpleHeader'
import {getQueryString,getLocal } from '@/common/js/utils'
export default {
  data() {
    return {
      detail: {
        goodsCarouselList: [],

      },
      tabActive:"1",
      evaluateList:[],
      total: 0, // 总条数
      imgBool:false,
      imgBigUrl:[],
      boxHeight:document.body.clientWidth,
      startIndex:0,
      weChatBool:false
    }
  },
  components: {
    sHeader
  },
  async mounted() {
    let shareId = getQueryString('shareId')
    if(shareId) localStorage['invitationCode'] = shareId
    const { id } = this.$route.params
    const { data } = await getDetail(id,{sharingUserId:shareId})
    this.detail = data
    this.getEvaluateList(id)
    const token = getLocal('token')
    if (token) {
      this.$store.dispatch('updateCart')
    }
  },
  methods: {
    isImg(url){
      let arr = url.split(".")
      let str = arr[arr.length-1]
      return ['jpg','jpeg','png'].includes(str)
    },
    goBack() {
      this.$router.go(-1)
    },
    goTo() {
      this.$router.push({ path: '/cart' })
    },
    customerServe(){
      // window.location.href = 'https://wa.me/message/RZKYVEUNKSKRN1'
      this.weChatBool = true
    },
    async addCart() {
      const { data, resultCode } = await addCart({ goodsCount: 1, goodsId: this.detail.goodsId }).then(res => {
        if(res.resultCode == 200){
          this.$toast.success(this.$t('t.addSuccessfully'))
        }
      }).catch(() => {
        if(localStorage['token']){
          this.$store.dispatch('updateCart')
          this.$router.push({ path: '/cart' })
        }
      });
    },
    async goToCart() {
      await addCart({ goodsCount: 1, goodsId: this.detail.goodsId }).then(res => {
        if(localStorage['token']){
          this.$store.dispatch('updateCart')
          this.$router.push({ path: '/cart' })
        }
      }).catch(() => {
        if(localStorage['token']){
          this.$store.dispatch('updateCart')
          this.$router.push({ path: '/cart' })
        }
      });

    },
    async getEvaluateList(goodsId) {
      await getEvaluateList({goodsId,pageNumber: 1, pageSize: 1000}).then(res => {
        if (res.resultCode == 200) {
          this.evaluateList = res.data.list
          this.total = res.data.totalCount
        }
      })

    },
    filterDate(value){
      if(!value) return '';
      let elem = new Date(value);
      let m = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"];
      let d = ["st","nd","rd","th"];
      let langBool = this.$i18n.locale=='en'?true:false
      let mn = elem.getMonth();//月
      let dn = elem.getDate();//日
      let dns;
      let dateStr = ''
      if(((dn%10)<1) ||((dn%10)>3)){
        dns=d[3];
      }
      else {
        dns = d[(dn % 10) - 1];
        if ((dn == 11) || (dn == 12)) {
          dns = d[3];
        }
      }
      if(langBool){
        dateStr = m[mn-1]+' '+dn + dns
      }else{
        dateStr = mn+'月'+dn+'日'
      }
      return dateStr
    },
  },
  computed: {
    count () {
      return this.$store.state.cartCount
    }
  }
}
</script>

<style lang="less">
  @import '../common/style/mixin';
  .product-detail {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    .detail-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: 44px;
      .detail-swipe-wrap {
        .my-swipe .van-swipe-item {
          img {
            width: 100%;
            /*height: 250px;*/
            // height: 300px;
          }
        }
      }
      .product-info {
        padding: 0 10px;
        .product-title {
          font-size: 18px;
          text-align: left;
          color: #333;
        }
        .product-desc {
          font-size: 12px;
          text-align: left;
          color: #999;
          padding: 5px 0;
        }
        .product-price {
          .fj();
          align-items: center;
          span:nth-child(1) {
            color: #F63515;
            font-size: 22px;
          }
          span:nth-child(2) {
            color: #999;
            font-size: 16px;
          }
        }
      }
      .product-intro {
        width: 100%;
        ul {
          .fj();
          width: 100%;
          margin: 10px 0;
          li {
            flex: 1;
            padding: 5px 0;
            text-align: center;
            font-size: 15px;
            border-right: 1px solid #999;
            box-sizing: border-box;
            &:last-child {
              border-right: none;
            }
          }
        }

      }
    }
    .van-goods-action-button--warning {
      background: linear-gradient(to right, #F5BA45, #FF6B01)
    }
    .van-goods-action-button--danger {
      background: linear-gradient(to right, @theme, #ee0a24)
    }
  }
  .product-content {
    /*padding: 0 20px;*/
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  .box{
  }
  .headSculptureImage{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .nameBox{
    font-size: 18px;
    margin-left: 15px;
    font-weight: bold;
    color: #000;
  }
  .contBox{
    background: #fff;
    border-radius: 6px;
    padding: 15px;
    margin-top: 8px;
    margin-left: 20px;

    .content{
      margin-top: 5px;
      color: #333;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .imgBox{
      width: 90px;
      height: 90px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .padding15{
    padding: 15px;
  }
  .color99{
    color: #999;
    margin-bottom: 5px;
  }
</style>
