
<template>
  <div class="user-box">
    <s-header :isMenu="false" :name="$t('t.mine')"></s-header>
    <div class="user-info"  @click="goTo('setting')">
      <div class="info">
        <img :src="user.headSculptureImage?user.headSculptureImage:require('../assets/image/avatar.jpg')"/>
        <!--<img src="../assets/image/avatar.jpg"/>-->
        <div class="user-desc">
          <span>{{$t('t.nickname')}}：{{ user.nickName }}</span>
          <span>{{$t('t.loginName')}}：{{ user.loginName }}</span>
          <span class="name">{{$t('t.personalSignature')}}：{{ user.introduceSign }}</span>
        </div>
      </div>
    </div>
    <van-row class="borderButtE4 color33 paddBott10 marTop15" type="flex" justify="space-around">
      <van-row>
        <van-row class="textCenter size14 color99">{{$t('t.bonusBalance')}}</van-row>
        <van-row class="colorR textCenter size12 marTop5">
          <span class="fontB size16 ">{{user.commissionTotalAmount}}</span>
        </van-row>
      </van-row>
      <van-row>
        <van-row class="textCenter size14 color99">{{$t('t.accumulatedBonus')}}</van-row>
        <van-row class="colorG textCenter size12 marTop5">
          <span class="fontB size16 ">{{user.accumulatedCommission}}</span>
        </van-row>
      </van-row>
      <van-row>
        <van-row class="textCenter size14 color99">{{$t('t.withdrawing')}}</van-row>
        <van-row class="color33 textCenter size12 marTop5">
          <span class="fontB size16 ">{{user.withdrawalAmount}}</span>
        </van-row>
      </van-row>
      <van-row @click="withdrawalFn">
        <van-row class="textCenter size14 color99">{{$t('t.withdrawalApplication')}}</van-row>
        <van-row class="textCenter marTop5">
          <van-icon name="card" class="colorW" size="25" />
        </van-row>
      </van-row>
    </van-row>

    <ul class="user-list">
      <li @click="goTo('order')">
        <van-row>
          <van-icon class="colorG marRight5" name="todo-list-o" />
          <span>{{$t('t.myOrder')}}</span>
        </van-row>

        <van-icon name="arrow" />
      </li>
      <li @click="goTo('address?from=mine')">
        <van-row>
          <van-icon class="colorG marRight5" name="location-o" />
          <span>{{$t('t.addressManagement')}}</span>
        </van-row>
        <van-icon name="arrow" />
      </li>
      <li @click="goTo('Share')" v-if="false">
        <van-row>
          <van-icon class="colorR marRight5" name="gift-o" />
          <span>{{$t('t.shareProfits')}}</span>
        </van-row>
        <van-row>
          <span class="colorBB size12">{{$t('t.accumulatedBonus')}}<span class="colorTheme fontB size14 paddLR10">{{user.accumulatedCommission || 0 | wq_mFormat}}</span>{{$t('t.bonus')}}</span>
          <van-icon name="arrow" ></van-icon>
        </van-row>
      </li>
      <li @click="goTo('daTask')" v-if="false">
        <van-row>
          <van-icon class="colorR marRight5" name="fire" />
          <span>{{$t('t.moneyDoingMissions')}}</span>
        </van-row>
        <van-row>
          <span class="colorBB size12" v-if="remarkArr.length">{{$t('t.accumulateRewards')}}
            <span class="colorTheme fontB size14 paddLR10">
              <span v-for="(item,i) in remarkArr" :key="i">{{item.remark}}<span v-if="i<remarkArr.length-1">、</span></span>
            </span>
          </span>
          <van-icon name="arrow" ></van-icon>
        </van-row>
      </li>
      <li @click="goTo('setting')">
        <van-row>
          <van-icon class="colorG marRight5" name="setting-o" />
          <span>{{$t('t.accountManagement')}}</span>
        </van-row>
        <van-icon name="arrow" />
      </li>
      <li @click="goTo('returnProcess')">
        <van-row>
          <van-icon class="colorG marRight5" name="info-o" />
          <span>退货协议</span>
        </van-row>

        <van-icon name="arrow" />
      </li>
      <li @click="customerServe()">
        <van-row>
          <van-icon class="colorG marRight5" name="service-o" />
          <span>{{$t('t.customerService')}}</span>
        </van-row>
        <van-icon name="arrow" />
      </li>

      <!--<li @click="langBool = true">-->
        <!--<van-row>-->
          <!--<van-icon class="colorG marRight5" name="exchange" />-->
          <!--<span>{{$t('t.changeLang')}}</span>-->
        <!--</van-row>-->
        <!--<van-icon name="arrow" />-->
      <!--</li>-->
    </ul>

    <van-row class="padding20 marTop40">
      <van-button type="danger" block @click="logout">{{$t('t.logOut')}}</van-button>
    </van-row>
    <van-row class="padding20" type="flex" align="center" justify="center" v-if="!user.googleEmail">
      <div class="g_id_signin" id="g_id_signin"></div>
    </van-row>
    <van-popup v-model="langBool" position="bottom">
      <van-picker
        show-toolbar
        value-key="key"
        :columns="langArr"
        @confirm="switchLanguageFn"
        @cancel="langBool = false"
      ></van-picker>
    </van-popup>
    <van-popup v-model="weChatBool">
      <van-row class="width250 flexDirection padding20" type="flex" align="center" justify="center">
        <van-row class="marBott10 size16 fontB">请使用手机QQ扫描二维码</van-row>
        <img class="app" src="../assets/image/qqService.jpg" alt="">
        <van-row class="marTop10 size14">QQ：512612269</van-row>
        <van-row class="marTop10 size14">昵称：深圳暴富先生科技有限公司</van-row>
      </van-row>
    </van-popup>
    <nav-bar></nav-bar>
    <van-row class="color24 size14 paddBott60 paddTop30 textCenter copyRightBox"><span style="font-family:Arial;">深圳暴富先生科技</span>2024版权所有&nbsp;
      <a href="https://beian.miit.gov.cn/" class="colorBlue" target="_self">粤ICP备2024204260号</a>
    </van-row>
  </div>
</template>


<script>
import navBar from '@/components/NavBar'
import sHeader from '@/components/SimpleHeader'
import { getUserInfo,logout ,bindGoogle,withdrawalFn,taskSelect} from '../service/user'
import {vantLocales} from '../lang'
import { setLocal } from '@/common/js/utils'
export default {
  components: {
    navBar,
    sHeader
  },
  data() {
    return {
      user: {},
      langBool:false,
      langArr:[
        {value:'zhCN',key:'切换语言（中文）'},
        {value:'en',key:'Switch language(English)'},
        {value:'id',key:'Ganti bahas(Bahasa indonesia)'},
        {value:'th',key:'สลับภาษา(ภาษาไทย)'}
      ],
      remarkArr:[],
      weChatBool:false
    }
  },
  async mounted() {
    this.getUserInfo()
    this.taskSelect()
  },
  created() {
    window.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id:'262405584003-24gia4qmh47cnjj24p1evugajth29hlp.apps.googleusercontent.com',
        auto_select: false,
        callback: this.handleCredentialResponse,
      });
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        {
          theme: "filled_blue",
          size: 'large',
          width:'250',
          type: 'standard',
          text: 'signin_with',
          locale:"en",
        }
      );
    })
  },
  methods: {
    customerServe(){
      // window.location.href = 'https://wa.me/message/RZKYVEUNKSKRN1'
      this.weChatBool = true
    },
    async taskSelect(){
      const { data } = await taskSelect()
      if(data.maxNumList) {
        this.remarkArr = data.taskConfigParam.taskConfigParamList.filter(t=>data.maxNumList.includes(t.maxNum))
      }
    },
    async getUserInfo(){
      const { data } = await getUserInfo()
      this.user = data
    },
    withdrawalFn(){
      this.$dialog.confirm({
        title: this.$t('t.sureWithdrawal'),
      }).then(() => {
        if(this.user.commissionTotalAmount<1){
          this.$notify(this.$t('t.amountWithdrawalNotbeLess1'))
          return
        }
        withdrawalFn({withdrawalCommissionAmount:this.user.commissionTotalAmount}).then(res => {
          if (res.resultCode == 200) {
            this.$toast.success(this.$t('t.withdrawalApplicationReview'));
            this.getUserInfo()
          }
        })
      }).catch(() => {
        // on cancel
      });

    },
    async handleCredentialResponse(response) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      let credential = response.credential
      await bindGoogle({credential});
      this.$toast.success(this.$t('t.bindingSuccessful'))
    },
    // 切换语言
    switchLanguageFn(lang){
      // vant-ui组件国际化
      let langVal = lang.value=='zhCN'?'zh':lang.value
      this.$i18n.locale = langVal//this.$i18n.locale=='en'?'zh':'en'
      vantLocales(this.$i18n.locale)
      localStorage['lang'] = this.$i18n.locale
      this.$store.dispatch('updateLang',lang.value)
      this.langBool = false
      location.reload([true])   //重新加载当前文档
    },
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    async logout() {
      const { resultCode } = await logout()
      if (resultCode == 200) {
        setLocal('token', '')
        window.location.href = '/'
      }
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .user-box {
    .user-info {
      width: 94%;
      margin: 10px;
      /*height: 115px;*/
      background: linear-gradient(90deg, @primary, @theme);
      /*background-image: linear-gradient(to right, #6faeef , #047df9);*/
      box-shadow: 0 2px 5px @primary;
      border-radius: 6px;
      margin-top: 50px;
      .info {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 15px;
        .boxSizing();
        img {
          .wh(60px, 60px);
          border-radius: 50%;
          margin-top: 4px;
        }
        .user-desc {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
          }
        }
        .account-setting {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 13px;
          color: #fff;
          .van-icon-setting-o {
            font-size: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
        }
      }
    }
    .user-list {
      padding: 0 20px;
      margin-top: 20px;
      li {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .van-icon-arrow {
          margin-top: 13px;
        }
      }
    }
  }
</style>
